<!--
 * @Author: 刘格优
 * @Date: 2020-02-26 15:28:16
 * @LastEditors: 刘格优
 * @LastEditTime: 2020-08-26 18:44:16
 -->

<template>
  <div style="
  background-color: #ffffff;">
    <div class="title">
      <div class="titleImg">
        <img src="@/assets/nh/nhwxgrzxbjt.jpg"
             alt />
      </div>
      <div class="income">
        <div class="titIcon">
          <!-- <span class="income_text">可提现(元) <md-icon color="#fff"
                     name="visible"
          size="lg"></md-icon></span>-->
          <div>
            <img src="@/assets/nh/grzx_icon1.png"
                 class="topIcon"
                 alt
                 @click="toCustomerserver" />
          </div>
          <div class="iconRight">
            <div style="position: relative;">
              <img src="@/assets/nh/grzx_icon2.png"
                   class="topIcon"
                   alt
                   @click="$router.push({path:'/drnews'})" />
              <div class="red"
                   v-if="wdmsgdata.num > 0">{{wdmsgdata.num > 9 ? '9+' : wdmsgdata.num}}</div>
            </div>
            <img src="@/assets/nh/grzx_icon3.png"
                 class="topIcon right"
                 @click="toSetup"
                 alt />
          </div>
        </div>
        <div class="income_num">
          <div class="num">
            <div class="l_img">
              <div class="numincone">
                <img src="@/assets/nh/nhwxtx-01.png"
                     alt />
              </div>
              <img :src="form.headimg?form.headimg: this.user.headimg"
                   alt />
            </div>

            <div class="l_msg">
              <div class="lm_top">
                <div class="lm_name">
                  <span>{{form.empname?form.empname: this.user.empname}}</span>
                </div>
                <div class="top_authentication">
                  <img src="@/assets/nh/yrz.png"
                       v-if="form.zyzstatus == '0'" />
                  <img src="@/assets/nh/nhwxgrzx-010.png"
                       v-if="form.zyzstatus != '0'" />

                  <!-- <span>{{ form.zyzstatus == '0' ? '已认证':'未认证'}}</span> -->
                </div>
              </div>
              <div class="lm_bottom"
                   v-show="this.user.rytype == 'W'">
                <span>ID:{{form.empno}}</span>
              </div>
            </div>
          </div>
          <!-- <div class="card">
            <div class="c_text"
                 @click="toMyScore">
              <img src="@/assets/image/nhyhk.png"
                   style="margin-right: 0.1rem;" />
              <span>我的积分</span>
              <md-icon color="#fff"
                       name="arrow-right"
                       size="md"></md-icon>
            </div>
          </div> -->
          <div class="jlcard card">
            <div class="c_text"
                 @click="toYhqshow">
              <img src="@/assets/image/nhyhk.png"
                   style="margin-right: 0.1rem;" />
              <span>我的奖励券</span>
              <md-icon color="#fff"
                       name="arrow-right"
                       size="md"></md-icon>
            </div>
          </div>
        </div>
        <div class="money_top">
          <div class="mt_item"
               @click="toPolicyDetails">
            <div class="item_num">
              <span>{{form.wjs?form.wjs: '0.00'}}</span>
            </div>
            <div class="item_text">
              <span>结算中(元)</span>
            </div>
          </div>
          <div class="mt_item"
               @click="toPolicyDetails">
            <div class="item_num">
              <span>{{form.jsz?form.jsz: '0.00'}}</span>
            </div>
            <div class="item_text">
              <span>可提现(元)</span>
            </div>
          </div>
          <div class="mt_item"
               @click="toPolicyDetails">
            <div class="item_num">
              <span>{{form.yjs?form.yjs: '0.00'}}</span>
            </div>
            <div class="item_text">
              <span>累计收入(元)</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wd_practising"
         @click="toZz">
      <div class="left">
        <div>
          <img src="@/assets/nh/zzzz_left.png"
               class="zzzzIcon"
               alt />
        </div>
        <div>
          <span>保险执业资质</span>
        </div>
      </div>
      <div class="right">
        <div>
          <span>立即查看</span>
        </div>
        <div>
          <img src="@/assets/nh/zzzz_right.png"
               class="zzzzIcon"
               alt />
        </div>
      </div>
    </div>
    <div class="wd_mine">
      <div class="mine_item"
           @click="toOrderform">
        <div>
          <img src="@/assets/nh/wddd.png"
               class="mineImg"
               alt />
        </div>
        <div class="mineText">
          <span>我的订单</span>
        </div>
      </div>
      <div class="mine_item"
           @click="toMysc">
        <div>
          <img src="@/assets/nh/wdsc.png"
               class="mineImg"
               alt />
        </div>
        <div class="mineText">
          <span>我的收藏</span>
        </div>
      </div>
      <div class="mine_item"
           @click="toMyTeam">
        <div>
          <img src="@/assets/nh/xbgl.png"
               class="mineImg"
               alt />
        </div>
        <div class="mineText">
          <span>我的团队</span>
        </div>
      </div>
      <div class="mine_item"
           @click="toAchievement">
        <div>
          <img src="@/assets/nh/wdtd.png"
               class="mineImg"
               alt />
        </div>
        <div class="mineText">
          <span>团队业绩</span>
        </div>
      </div>
    </div>
    <div class="insurancePolicy">
      <div class="title">
        <span>保单</span>
        <div class="wd_manage">
          <div class="manage_item"
               @click="$router.push('/newpolicy')">
            <div class="manageText">
              <!-- <div class="ztdivsp"></div> -->
              <div class="ztspan oneleft">新保保单</div>
              <p class="pecsc">保单在线查看</p>
            </div>
            <div style="margin-left: 0.1rem;position: relative;width: 0.7rem;">
              <img src="@/assets/nh/nhwxgrzx-02.png"
                   class="manageImgimg"
                   alt />
            </div>
          </div>
          <div class="manage_item"
               @click="$router.push('/renewal')">
            <div class="manageText right">
              <div class="ztspan tworight">续期保单</div>
              <p class="pecsc">到期/续期提醒</p>
            </div>
            <div style="margin-left: 0.1rem;position: relative;width: 0.7rem;">
              <img src="@/assets/nh/nhwxgrzx-01.png"
                   class="manageImgimg"
                   alt />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="wd_change">
      <span class="service">服务</span>
      <div style="box-shadow:2px 2px 20px #F5F5F5;">
        <div class="change-one">
          <div class="change_item"
               @click="toArtificialUnderwriting('rghb')"
               style="border-bottom: 0.01rem solid #f3f4f5;border-right: 0.01rem solid #f3f4f5;">
            <div class="change-Text">
              <p class="change-span">人工核保</p>
              <p class="change-pecsc">联系客服查看</p>
            </div>
            <div style="margin-right: 0.3rem;position: relative;width: 1.3rem;">
              <div style="position: absolute;top: 0.8rem;">
                <img src="@/assets/nh/nhwxgrzx-03.png"
                     style="height: 0.8rem;"
                     alt />
              </div>
            </div>
          </div>
          <div class="change_item"
               @click="toArtificialUnderwriting('lp')"
               style="border-bottom: 0.01rem solid #f3f4f5;">
            <div class="change-Text">
              <p class="change-span">理赔</p>
              <p class="change-pecsc">理赔服务指引</p>
            </div>
            <div style="margin-right: 0.3rem;position: relative;width: 1.3rem;">
              <div style="position: absolute;top: 0.8rem;">
                <img src="@/assets/nh/nhwxgrzx-04.png"
                     style="height: 0.8rem;"
                     alt />
              </div>
            </div>
          </div>
        </div>
        <div class="change-two">
          <div class="change_item"
               @click="toAdvice"
               style="border-right: 0.01rem solid #f3f4f5;">
            <div class="change-Text">
              <p class="change-span">意见反馈</p>
              <p class="change-pecsc">请留下宝贵意见</p>
            </div>
            <div style="margin-right: 0.3rem;position: relative;width:1.3rem;">
              <div style="position: absolute;top: 0.8rem;">
                <img src="@/assets/nh/nhwxgrzx-05.png"
                     style="height: 0.8rem;"
                     alt />
              </div>
            </div>
          </div>
          <div class="change_item"
               @click="toWebsite">
            <div class="change-Text">
              <p class="change-span">关于我们</p>
              <p class="change-pecsc">汇立天下是什么</p>
            </div>
            <div style="margin-right: 0.3rem;position: relative;width:1.3rem;">
              <div style="position: absolute;top:0.8rem;">
                <img src="@/assets/nh/nhwxgrzx-06.png"
                     style="height: 0.8rem;"
                     alt />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div style="width: 50%;height: auto;margin: 0.8rem auto 0.5rem auto;">
        <img src="@/assets/nh/bottomimg.png" style="width:100%;height: auto;" alt />
      </div>-->
    </div>
    <div class="bg_bottom">
      <div class="bot-con">
        <img src="@/assets/nh/bottomimg.png"
             alt />
      </div>
    </div>
    <div class="nullbox"></div>
    <div v-show="showZz"
         class="showZyImg">
      <div class="zyImg">
        <div>
          <img src="@/assets/nh/zyzz.png"
               class="manageImg"
               alt />
        </div>
        <div class="zzMsg">
          <div class="itemTitle">
            <span>执业资质信息</span>
            <div class="code">
              <!-- <span>NO12345678900987654321123456</span> -->
              <span v-show="showZzform.certno">NO.{{showZzform.certno}}</span>
            </div>
          </div>
          <!-- <div class="zzMsgItem">
            <div class="showText">执业证编号</div>
            <div class="showNum">{{showZzform.certno}}</div>
          </div>-->
          <div class="zzMsgItem">
            <div class="showText">姓名</div>
            <div class="showNum">{{showZzform.name}}</div>
          </div>
          <div class="zzMsgItem">
            <div class="showText">证件号码</div>
            <div class="showNum">{{showZzform.cardnum}}</div>
          </div>
          <div class="zzMsgItem">
            <div class="showText">所属公司</div>
            <div class="showNum">深圳市南华保险经纪有限公司</div>
          </div>
        </div>
        <div class="closeImg">
          <img src="@/assets/nh/sctp.png"
               @click="showZz = false"
               alt />
        </div>
      </div>
    </div>
    <div>
      <nhwx></nhwx>
    </div>
    <md-landscape v-model="showPic">
      <div class="s-box">
        <div class="s_img">
          <img src="@/assets/image/centerqdjf.png" />
        </div>
        <div class="s-bom">
          <div class="s-title">
            <div class="s-t1">
              <span>
                <i class="iconfont iconzuobianhenggang" />
              </span>
            </div>
            <div class="s-t2">
              <p>奖励</p>
            </div>
            <div class="s-t3">
              <span>
                <i class="iconfont iconzuobianhenggang" />
              </span>
            </div>
          </div>
          <div class="s-res">
            <div class="s-r1">
              <span>积分</span>
            </div>
            <div class="s-r2">
              <span>+ {{jf}}</span>
            </div>
          </div>
          <div class="s-btn">
            <button @click="btn"
                    class="btn">收下</button>
          </div>
        </div>
      </div>
    </md-landscape>
  </div>
</template>

<script>
import {
  wdmsg,
  getEmpnoPersonal,
  getZYZMessage,
  getJf,
  getJfSum
} from '@/api/basic/index'
import { Toast, Dialog } from 'mand-mobile'
import { getStorage } from '@/lib/util'
import nhwx from '@/views/basic/home/component/nhwx'
export default {
  components: { nhwx },
  data () {
    return {
      wdmsgdata: { num: 0 },
      user: {},
      form: {},
      showZz: false,
      showZzform: {
        certno: '',
        begdate: '',
        enddate: ''
      },
      jfdata: {},
      showPic: false,
      jf: 0,
      JFNUM: 0,
      getjfnum: {}
    }
  },
  created () {
    this.user = getStorage('u_s', '')
    if (this.user.rytype == 'W') {
      this.getData()
      // this.getqdjf();
    }
    this.wdmsg()
  },
  methods: {
    wdmsg () {
      wdmsg().then(res => {
        this.wdmsgdata = res.data.data
      })
    },
    getData () {
      getEmpnoPersonal().then(res => {
        this.form = res.data.data
      })
    },
    toZz () {
      if (this.form.zyzstatus == '0') {
        getZYZMessage({ empno: this.form.empno }).then(res => {
          this.showZzform = res.data.data
          this.showZz = true
        })
      } else {
        Dialog.alert({
          content: '您提交的展业申请正在审核中，审核通过会发送短信通知。',
          confirmText: '确定'
        })
      }
    },
    toSetup () {
      this.$router.push(
        `/setup?empname=${this.form.empname}&empno=${this.form.empno}&phone=${this.form.phone}&zyzstatus=${this.form.zyzstatus}&sfzstatus=${this.form.sfzstatus}`
      )
    },
    toPolicyDetails () {
      this.$router.push({ path: `/policyDetails` })
    },
    toMyScore () {
      this.$router.push({ path: '/myScore' })
    },
    toYhqshow () {
      this.$router.push({ path: '/yhqshow' })
    },
    toAdvice () {
      this.$router.push({ path: '/advice' })
    },
    toCustomerserver () {
      // if (this.user.rytype == 'W') {
      this.$router.push({ path: '/customerserver' })
      // }
    },
    toWebsite () {
      // if (this.user.rytype == 'W') {
      // 微官网
      this.$router.push({ path: '/aboutUs' })
      // }
    },
    toOrderform () {
      this.$router.push({ path: '/orderform' })
    },
    toMysc () {
      this.$router.push({ path: '/mysc' })
    },
    toMyTeam () {
      this.$router.push({ path: '/wxmyteam' })
    },
    toAchievement () {
      this.$router.push({ path: '/businesstrack' })
    },
    toArtificialUnderwriting (val) {
      this.$router.push(`/artificialUnderwriting?type=${val}`)
    },
    getqdjf () {
      getJf().then(res => {
        this.jfdata = res.data.data
        if (res.data.data === null) {
          this.jf = 0
        } else {
          this.jf = this.jfdata.JF
          // 判断今天第一次登录
          if (
            this.jfdata.state === 1 &&
            getStorage('u_s', '').rytype != 'M' &&
            getStorage('u_s', '').rytype != 'Y'
          ) {
            this.showPic = true
          }
        }
        this.getJfSum()
      })
    },
    getJfSum () {
      getJfSum().then(res => {
        this.getjfnum = res.data.data
        if (res.data.data === null) {
          this.JFNUM = 0
          this.form.jfnum = 0
        } else {
          this.JFNUM = this.getjfnum
          this.form.jfnum = this.getjfnum
        }
      })
    },
    btn () {
      this.showPic = false
    }
  }
}
</script>
<style lang="stylus" scoped>
.title {
  position: relative;

  .titleImg {
    position: relative;

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  .income {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    padding-top: 56px;

    .titIcon {
      display: flex;
      justify-content: space-between;
      padding: 0px 35px;
      padding-bottom: 27px;

      .iconRight {
        display: flex;

        .right {
          margin-left: 0.65rem;
        }
      }
    }

    span {
      color: #fff;
    }

    .income_text {
      padding-left: 115px;
      font-size: 0.33rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
    }

    .income_num {
      padding-top: 7px;
      padding-left: 51px;

      .card {
        position: absolute;
        right: 0;
        top: 1.3rem;
      }

      .jlcard {
        position: absolute;
        right: 0;
        top: 2.4rem;
      }

      .num {
        display: flex;
        flex-wrap: wrap;

        .l_img {
          // padding-right: 55px;
          border: 5px solid #ffffff;
          border-radius: 50%;
          margin-right: 44px;
          position: relative;
          height: 1.9rem;
          width: 1.9rem;

          img {
            width: 1.78rem;
            height: 1.78rem;
            border-radius: 50%;
            display: block;
          }
        }

        .l_msg {
          .lm_top {
            display: flex;
            flex-direction: column;
            height: 50%;

            .lm_name {
              padding-right: 16px;

              span {
                font-size: 0.5rem;
                font-family: PingFang SC;
                font-weight: bold;
                color: rgba(255, 255, 255, 1);
              }
            }

            .top_authentication {
              padding-top: 0.2rem;
              text-align: left;

              img {
                height: 46px;
                width: auto;
              }
            }
          }

          .lm_bottom {
            padding-top: 39px;

            span {
              font-size: 0.33rem;
              font-family: PingFang SC;
              font-weight: 500;
              color: rgba(255, 235, 230, 1);
            }
          }
        }
      }

      .card {
        background-color: #FD894F;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        text-align: center;

        .c_text {
          padding: 20px 33px 20px 36px;

          img {
            width: 33px;
            height: auto;
          }

          span {
            font-size: 0.33rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: rgba(255, 255, 255, 1);
          }
        }
      }
    }

    .money_top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // flex-wrap wrap
      padding: 0px 50px 0px 50px;

      // border-bottom: 1px solid #F6F6F6;
      .mt_item {
        text-align: center;
        width: 33%;

        // padding-right 85px
        .red {
          color: rgba(255, 77, 41, 1) !important;
        }

        .item_num {
          span {
            font-size: 0.39rem;
            font-family: OPPOSans;
            font-weight: bold;
            color: rgba(255, 255, 255, 1);
            // font-size: 0.44rem;
            // font-family: PingFang SC;
            // font-weight: bold;
            // color: rgba(56, 56, 56, 1);
          }

          img {
            width: auto;
            height: 60px;
            display: block;
          }
        }

        .item_text {
          padding-bottom: 21px;

          span {
            font-size: 0.29rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: rgba(255, 222, 215, 1);
            // font-size: 0.32rem;
            // font-family: PingFang SC;
            // font-weight: 500;
            // color: rgba(152, 152, 152, 1);
          }
        }
      }
    }

    .income_bottom {
      border-top: 1px solid #FD894F;
      display: flex;
      justify-content: space-between;
      margin: 0px 30px;

      .addItem {
        width: 50%;
        padding-left: 92px;

        .addTxt {
          padding-top: 35px;
          padding-bottom: 24px;
          font-size: 0.32rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgba(255, 255, 255, 1);
        }

        .addNum {
          padding-bottom: 38px;
          font-size: 0.39rem;
          font-family: OPPOSans;
          font-weight: bold;
          color: rgba(255, 255, 255, 1);
        }
      }

      .left {
        padding-left: 249px;
        border-right: 1px solid #FD894F;
      }
    }
  }
}

.wd_title {
  background-color: #F6F6F6;
  padding: 106px 54px 0 54px;

  .wdt_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 30px;

    .wdt_left {
      display: flex;
      flex-wrap: wrap;

      .l_img {
        padding-right: 55px;

        img {
          width: 178px;
          height: 178px;
          border-radius: 50%;
        }
      }

      .l_msg {
        .lm_top {
          display: flex;
          align-items: center;
          height: 50%;

          .lm_name {
            padding-right: 30px;

            span {
              font-size: 0.5rem;
              font-family: PingFang SC;
              font-weight: bold;
              color: rgba(48, 49, 51, 1);
            }
          }

          .top_authentication {
            span {
              background-color: #FEE3E1;
              padding: 13px 15px;
              font-size: 0.26rem;
              font-family: PingFang SC;
              font-weight: 500;
              color: rgba(231, 149, 119, 1);
            }
          }
        }

        .lm_bottom {
          padding-top: 39px;

          span {
            font-size: 0.33rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: rgba(152, 152, 152, 1);
          }
        }
      }
    }

    .wdt_right {
      img {
        width: auto;
        height: 49px;
      }
    }
  }

  .wdt_bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 60px;
    // background-image: linear-gradient(#FCF2DC, #E3C79E);
    background: -webkit-linear-gradient(left, #FCF2DC, #E3C79E);
    background: -o-linear-gradient(right, #FCF2DC, #E3C79E);
    background: -moz-linear-gradient(right, #FCF2DC, #E3C79E);
    background: linear-gradient(to right, #FCF2DC, #E3C79E);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    .bxzz_left {
      display: flex;
      align-items: center;

      .bxzz_img {
        padding-right: 20px;

        img {
          width: auto;
          height: 33px;
          display: block;
        }
      }

      .bxzz_text {
        span {
          font-size: 0.33rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgba(158, 118, 40, 1);
        }
      }
    }

    .bxzz_right {
      span {
        font-size: 45px;
        font-family: PingFang SC;
        font-weight: 500;
        color: rgba(158, 118, 40, 1);
        border: 1px solid #E1BE8A;
        padding: 5px 25px;
        border-radius: 20px;
      }

      img {
        width: auto;
        height: 33px;
        display: block;
      }
    }
  }
}

.wd_money {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  background-color: #FFFEFF;

  .money_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // flex-wrap wrap
    padding: 54px 54px 0px 54px;
    border-bottom: 1px solid #F6F6F6;

    .mt_item {
      text-align: center;

      // padding-right 85px
      .red {
        color: rgba(255, 77, 41, 1) !important;
      }

      .item_num {
        span {
          font-size: 0.44rem;
          font-family: PingFang SC;
          font-weight: bold;
          color: rgba(56, 56, 56, 1);
        }

        img {
          width: auto;
          height: 60px;
          display: block;
        }
      }

      .item_text {
        padding-top: 30px;
        padding-bottom: 30px;

        span {
          font-size: 0.32rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgba(152, 152, 152, 1);
        }
      }
    }
  }

  .money_bottom {
    display: flex;
    justify-content: space-between;
    padding: 50px 200px;

    .mb_item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .integral {
        padding-right: 15px;

        span {
          font-size: 0.33rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgba(131, 131, 131, 1);
        }
      }

      .integralNum {
        span {
          font-size: 0.33rem;
          font-family: PingFang SC;
          font-weight: bold;
          color: rgba(56, 56, 56, 1);
        }
      }

      .jfIcon {
        font-size: 33px;
        color: rgba(131, 131, 131, 1);
        display: block;
      }
    }
  }
}

.wd_practising {
  padding: 0px 47px;
  margin-top: 45px;
  margin-left: 35px;
  margin-right: 35px;
  background-image: url('~@/assets/nh/zyzz_bjt.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
  height: 123px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    display: flex;
    align-items: center;

    // width: auto;
    img {
      height: 37px;
      width: auto;
      display: block;
      padding-right: 15px;
    }

    span {
      font-size: 0.36rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
    }
  }

  .right {
    background-color: #fff;
    padding: 17px 25px 18px 26px;
    border-radius: 100px;
    display: flex;
    align-items: center;

    // width: auto;
    img {
      height: 37px;
      width: auto;
      display: block;
      padding-left: 8px;
    }

    span {
      font-size: 0.33rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: rgba(176, 143, 91, 1);
    }
  }
}

.wd_mine {
  display: flex;
  justify-content: space-between;
  // border-top: 30px solid #F6F6F6;
  background-color: #FFFEFF;
  padding: 63px 89px 41px 89px;

  .mine_item {
    text-align: center;

    .mineImg {
      height: auto;
      width: 89px;
    }

    .mineText {
      padding-top: 0.05rem;

      span {
        font-size: 0.33rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: rgba(56, 56, 56, 1);
        // font-size: 0.33rem;
        // font-family: PingFang SC;
        // font-weight: 500;
        // color: rgba(56, 56, 56, 1);
      }
    }
  }
}

.insurancePolicy {
  border-top: 30px solid #F3F4F5;

  .title {
    padding-top: 1rem;
    padding-left: 0.34rem;
    padding-right: 0.34rem;
    background-color: #ffffff;
    margin-bottom: 0.7rem;

    span {
      font-size: 0.53rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: #3d3d3d;
    }
  }

  .wd_manage {
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    margin-top: 0.5rem;
  }

  .pecsc {
    color: rgb(152, 152, 152) !important;
    font-size: 0.29rem !important;
    font-family: PingFang-SC-Medium !important;
    padding: 0.03rem 0.03rem 0.03rem 0.1rem;
  }
}

.wd_manage {
  display: flex;
  justify-content: space-between;
  background-color: #FFFEFF;

  // padding: 50px 100px;
  .manage_item {
    display: flex;
    justify-content: center;
    width: 48%;
    height: 1.82rem;
    box-shadow: 2px 2px 20px #F5F5F5;
    border-radius: 0.1rem;

    .manageImg {
      height: 98px;
      width: auto;
      display: block;
    }

    .manageText {
      // padding-left: 30px;
      margin: auto 0.2rem auto 0;
      position: relative;

      .ztspan {
        font-size: 0.39rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: rgba(56, 56, 56, 1);
        padding: 0.03rem 0.03rem 0.03rem 0.1rem;
      }
    }
  }
}

.change-pecsc {
  color: rgb(152, 152, 152);
  font-size: 0.29rem;
  font-family: PingFang-SC-Medium;
}

.change-span {
  font-size: 0.39rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: rgba(56, 56, 56, 1);
}

.wd_change {
  background-color: #FFFEFF;
  padding-bottom: 80px;
  padding-left: 0.34rem;
  padding-right: 0.34rem;

  .change_item {
    display: flex;
    justify-content: space-between;
    width: 50%;
    height: 2rem;

    .ci_left {
      span {
        font-size: 0.39rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: rgba(56, 56, 56, 1);
      }
    }
  }
}

.s-box {
  width: 100%;

  // height 650px;
  .s_img {
    img {
      display: block;
    }
  }

  .s-bom {
    width: 100%;
    background-color: #FFFFFF;
    height: 400px;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    position: relative;
    top: -20px;
  }

  .s-title {
    display: flex;
    justify-content: space-around;
    line-height: 70px;

    .s-t1 {
      width: 30%;
      text-align: right;
    }

    .s-t2 {
      width: 25%;
      color: #C49961;
      font-size: 0.4rem;
      text-align: center;
    }

    .s-t3 {
      width: 30%;
      text-align: left;
    }

    p {
      font-size: 0.4rem;
    }
  }

  .s-res {
    display: flex;
    justify-content: space-between;
    line-height: 0.7rem;
    margin-top: 60px;

    .s-r1 {
      width: 45%;
      color: #C49961;
      text-align: right;
    }

    .s-r2 {
      width: 45%;
      color: #C49961;
      text-align: left;
    }

    span {
      font-size: 0.5rem;
    }
  }

  .s-btn {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 70px;

    .btn {
      font-size: 28px;
      background-color: color-primary;
      color: #ffffff;
      font-size: 0.35rem;
      width: 50%;
      height: 60px;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }
}

.showZyImg {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 9999;
  padding: 400px 130px;
  background: rgba(0, 0, 0, 0.7);

  .zyImg {
    position: relative;

    .zzMsg {
      position: absolute;
      top: 30%;
      width: 100%;

      .itemTitle {
        text-align: center;
        padding-bottom: 50px;

        span {
          font-size: 0.43rem;
          font-family: PingFang SC;
          font-weight: bold;
          color: rgba(46, 46, 46, 1);
          letter-spacing: 40;
        }

        .code {
          margin-top: 20px;

          span {
            font-weight: 500;
            font-size: 35px !important;
          }
        }
      }

      .zzMsgItem {
        display: flex;
        justify-content: space-between;
        margin: 25px 50px;
        padding-bottom: 25px;
        border-bottom: 1px solid #F6F6F6;

        .showText {
          font-size: 0.36rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgba(56, 56, 56, 1);
        }

        .showNum {
          font-size: 0.36rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgba(201, 201, 201, 1);
        }
      }
    }

    .closeImg {
      text-align: center;
      padding-top: 30px;

      img {
        width: 78px;
        height: auto;
      }
    }
  }
}

// .rightIcon {
// color: #D0CDCE;
// font-size: 0.39rem;
// }
.topIcon {
  height: 0.5rem;
  width: auto;
  display: block;
}

.manageImgimg {
  height: auto;
  width: 0.6rem;
  position: absolute;
  bottom: 0.5rem;
}

.service {
  font-size: 0.53rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #3d3d3d;
}

.change-one {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  margin-top: 0.5rem;
}

.change-two {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
}

.change-Text {
  margin: auto 0 auto 0.4rem;
}

.red {
  line-height: 0.4rem;
  text-align: center;
  color: #fff;
  font-size: 0.22rem;
  border-radius: 2rem;
  display: inline-block;
  background: #FB6822;
  border: 0.03rem solid #ffffff;
  position: absolute;
  left: 0.2rem;
  padding: 0 0.1rem;
  bottom: 0.39rem;
}

.numincone {
  position: absolute;
  bottom: 0.6rem;
  right: 0.8rem;
  width: 1.78rem;
  height: 1.78rem;
}

.ztdivsp {
  position: absolute;
  width: 1rem;
  height: 1rem;
}

.oneleft {
  background-image: url('~@/assets/nh/nhwxtpleft.png');
  background-size: auto 100%;
  background-repeat: no-repeat;
}

.tworight {
  background-image: url('~@/assets/nh/nhwxtpright.png');
  background-size: auto 100%;
  background-repeat: no-repeat;
}

.bg_bottom {
  width: 100%;
  height: 1.5rem;
  margin-bottom: 100px;
  background: #F6F6F6;

  .bot-con {
    width: 50%;
    margin: auto;
    line-height: 1.4rem;
  }
}

.nullbox {
  width: 100%;
  height: 1rem;
  background: #F6F6F6;
}
</style>
